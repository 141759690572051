import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from '@material-ui/core/Dialog';
import Flag from 'react-world-flags';

import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const languagesLong = {
    en: 'English',
    nl: 'Nederlands',
    fr: 'français',
    de: 'Deutsch',
};

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
}

export default function LanguageModal(props: SimpleDialogProps) {
    const { open } = props;
    const { originalPath, navigate } = useI18next();

    const handleClose = () => {
        navigate(`${originalPath}?hideLanguageModal=true`);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <List>
                {Object.entries(languagesLong).map(([short, long], index) => (
                    <Link
                        key={'link-lang-' + index}
                        to={`${originalPath}?hideLanguageModal=true`}
                        language={short}
                        style={{ textDecoration: 'none' }}
                    >
                        <ListItem button key={short}>
                            <ListItemAvatar>
                                <Flag code={short === 'en' ? 'gb' : short} />
                            </ListItemAvatar>
                            &nbsp;
                            <ListItemText primary={long} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Dialog>
    );
}
